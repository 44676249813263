import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux'
import { useNavigate } from "react-router-dom";
import { userDetails } from "../store/authSlice";
import { Spinner } from "react-bootstrap";
import { useAuth } from "../context/AuthContext";

const ProtectedRoute = ({ children }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isAuthenticated } = useAuth();

    useEffect(() => {
        const checkAuthenticated = async () => {
            dispatch(userDetails()).then(res => {
                if (res.payload === undefined) {
                    navigate('/');
                } else {
                    if (res.payload.data.success === false) {
                        navigate('/');
                    }
                }  
            });
        }

        checkAuthenticated();
    }, [dispatch,navigate]);

    // return (isAuthenticated === true) ? children : <div className="loading show"> <Spinner className="loader" animation="grow" variant="primary" /> </div>;
    return (isAuthenticated === true) ? children : <div className="d-flex justify-content-center align-items-center" style={{ zIndex: 9999999, height: "100vh", width: "100vw", position: "fixed", top: 0, left: 0, backgroundColor: "rgba(0,0,0,0.5)" }}>
    <Spinner animation="border" role="status" variant="light">
        <span className="visually-hidden">Loading...</span>
    </Spinner>
    </div>;
};

export default ProtectedRoute;

// import React, { useEffect, useState } from 'react';
// import { useDispatch } from 'react-redux';
// import { useNavigate } from "react-router-dom";
// import { userDetails } from "../store/authSlice";
// import { Spinner } from "react-bootstrap";
// import { useAuth } from "../context/AuthContext";

// const ProtectedRoute = ({ children }) => {
//     const navigate = useNavigate();
//     const dispatch = useDispatch();
//     const { isAuthenticated, checkAuthentication } = useAuth();
//     const [isLoading, setLoading] = useState(true);

//     useEffect(() => {
//         if (!isAuthenticated) {
//             dispatch(userDetails())
//                 .unwrap()
//                 .then((res) => {
//                     if (res.data && res.data.success) {
//                         checkAuthentication(true); 
//                         setLoading(false);
//                         navigate('/');
//                     } else {
//                         navigate('/login');
//                     }
//                 })
//                 .catch(() => {
//                     navigate('/login');
//                 });
//         } else {
//             setLoading(false);
//         }
//     }, [dispatch, isAuthenticated, navigate]);

//     if (isLoading) {
//         return <div className="loading show"> <Spinner animation="grow" variant="primary" /> </div>;
//     }

//     return isAuthenticated ? children : <div className="loading show"> <Spinner animation="grow" variant="primary" /> </div>;
// };

// export default ProtectedRoute;
