import React from 'react'

export default function Widget({ count, title, icon, className }) {
    return (
        <div className={className}>
            <div className='d-flex align-items-start justify-content-between gap-4 widget-content'>
                <div>
                    <h4 className='fw-medium'>{count}</h4>
                    <p className=' text-capitalize'>{title}</p>
                </div>
                <div className='d-flex align-items-center justify-content-center me-4 rounded-2 widget-iconbox'>{icon}</div>
            </div>
        </div>
    )
}
