import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button, Toast, ToastContainer, Spinner } from "react-bootstrap";
// import { addCreditRequest  } from "../../../store/customerSlice";
// import { fetchWalletDetails, } from "../../../store/walletSlice";
import { useAuth } from "../../../context/AuthContext";
import { Formik, Form, Field } from "formik";
import "../CreditsLog/style.css";
// import { requestCreditSchema } from "../../../schema/validationSchemas";
// import { CheckCircle } from "react-feather";
import * as Yup from "yup";
import { addCreditRequest } from "../../../store/walletSlice";
import { CheckmarkBadge04Icon } from "../../../icons/icons";

const Credit = ({ show, handleClose }) => {
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("toast-success");
  const dispatch = useDispatch();
  const { user } = useAuth();
  // console.log("User", user);

  // useEffect(() => {
  //   if (show) {
  //       dispatch(fetchMyCredit(user.id));
  //   }
  // }, [show, dispatch, user.id, limit, currentPage, dateRange]);

  const initialCreditState = {
    credits: "",
    description: "",
  };

  const requestCreditSchema = Yup.object().shape({
    credits: Yup.number()
      .typeError("Credits must be a number")
      .min(1, "Credits must be at least 1")
      .required("Credits are required"),
  });

  //   const getCurrentFormattedDate = () => {
  //     const today = new Date();
  //     const year = today.getFullYear();
  //     const month = (today.getMonth() + 1).toString().padStart(2, "0");
  //     const day = today.getDate().toString().padStart(2, "0");
  //     return `${year}/${month}/${day}`;
  //   };

  const userCredits = useSelector(
    (state) => state.wallet.myCredit.available_credits
  );

  return (
    <>
      <ToastContainer position="top-end" className="p-3">
        <Toast
          onClose={() => setShowSnackbar(false)}
          show={showSnackbar}
          delay={3000}
          autohide
          bg={snackbarVariant}
          className="position-fixed top-3 end-0 me-3 shadow-none"
        >
          <Toast.Body className="d-flex align-items-center gap-1 lh-sm">
            <CheckmarkBadge04Icon
              width={18}
              height={18}
              color="#28c76f"
              strokeWidth="2"
            />
            <span className="text-capitalize">{snackbarMessage}</span>
          </Toast.Body>
        </Toast>
      </ToastContainer>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Request Credit</Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={{
            ...initialCreditState,
            available_credits: userCredits,
          }}
          validationSchema={requestCreditSchema}
          onSubmit={(values, { setSubmitting, resetForm, setErrors }) => {
            const formData = new FormData();

            formData.append("credits", values.credits);
            formData.append("description", values.description);
            formData.append("company_id", user.company_id);

            formData.append("_method", "POST");
            dispatch(addCreditRequest(formData))
              .then((res) => {
                if (res.payload && res.payload.success) {
                  // dispatch(
                  //   fetchWalletDetails({
                  //     id: user.company_id,
                  //     offset: currentPage,
                  //     limit,
                  //     search: "",
                  //     sort_by_key: sortKey,
                  //     sort_by_value: sortOrder,
                  //   }));
                  // refreshData();
                  setSnackbarMessage(res.payload.message);
                  setSnackbarVariant("toast-success");
                  setShowSnackbar(true);
                  resetForm();
                  handleClose();
                } else {
                  setSnackbarMessage(res.payload.message);
                  setErrors(res.payload.data);
                  setSnackbarVariant("toast-danger");
                  setShowSnackbar(true);
                }
              })
              .catch((error) => {
                setSnackbarMessage(
                  "Failed to request credit: " + error.message
                );
                setSnackbarVariant("toast-danger");
                setShowSnackbar(true);
              })
              .finally(() => {
                setSubmitting(false);
              });
          }}
        >
          {({ values, setFieldValue, isSubmitting, errors, touched }) => (
            <Form encType="multipart/form-data">
              <Modal.Body>
                <div className="modal-body">
                  {/* <div className="d-flex align-items-center gap-2 credit-visible mb-3">
                      <span><strong>Available Credits: </strong></span>
                      <p className="mb-0">
                        {userCredits}
                      </p>
                    </div> */}

                  <label className="form-label mt-5">
                    <b>Credits</b>
                  </label>
                  <Field
                    name="credits"
                    type="number"
                    placeholder=" "
                    className={`form-control ${
                      errors.credits && touched.credits ? "is-invalid" : ""
                    }`}
                    min="0"
                    onChange={(e) => {
                      setFieldValue("credits", e.target.value);
                    }}
                  />
                  {errors.credits && touched.credits && (
                    <div className="text-danger">{errors.credits}</div>
                  )}
                  <br />

                  <label className="form-label mt-5">
                    <b>Description</b>
                  </label>
                  <Field
                    name="description"
                    type="text"
                    placeholder=" "
                    className={`form-control `}
                    min="0"
                    onChange={(e) => {
                      setFieldValue("description", e.target.value);
                    }}
                  />
                  {/* {errors.description && touched.description && <div className="text-danger">{errors.description}</div>} */}
                  <br />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button type="submit" variant="primary" disabled={isSubmitting}>
                  {isSubmitting ? (
                    <>
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      Requesting...
                    </>
                  ) : (
                    "Request"
                  )}
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default Credit;
