import React from 'react'
import { Form } from 'react-bootstrap';

export default function Checkbox({ id, name, label, checked, onChange, className }) {
    return (
        <Form.Check
            label={label}
            name={name}
            type="checkbox"
            checked={checked}
            id={id}
            onChange={onChange}
            className={`${className} custom-checkbox`}
        />
    )
}
