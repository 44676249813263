import React from 'react'
import { Tabs, Tab } from "react-bootstrap";

export default function NavTabs({ defaultActiveKey, id, onSelect, tabsData, className }) {
    return (
        <Tabs
            defaultActiveKey={defaultActiveKey}
            id={id}
            onSelect={onSelect}
            className={`border-0 gap-1 cutom-nav-tabs ${className}`}
        >
            {
                tabsData.map((item) => {
                    return (
                        <Tab key={item.id} eventKey={item.id} title={
                            <div className='d-flex align-items-center tabs-gap justify-content-center'>
                                {item.icon && <span>{item.icon}</span>}
                                {item.title && <p className='fw-medium'>{item.title}</p>}
                            </div>
                        } />
                    )
                })
            }
        </Tabs>
    )
}
