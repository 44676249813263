import React, { useState, useEffect } from "react";
import CommonFormLabel from "../../../components/common-form-label";
import Input from "../../../components/input";
import ValidationFeedback from "../../../components/validation-feedback";
import CommomButton from "../../../components/common-button/index";
import ButtonLoader from "../../../components/button-loader/index";
import {
  Container,
  Image,
  Form,
  ToastContainer,
  Toast,
  Spinner,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { userForgotPassword } from "../../../store/authSlice";
import { ForgotPasswordSchema } from "../../../schema/validationSchemas";

import { ArrowLeftIcon, CheckmarkBadge04Icon } from "../../../icons/icons";

// const ForgotPassword = () => {
//   const [showToast, setShowToast] = useState(false);
//   const [toastMessage, setToastMessage] = useState('');
//   const [toastVariant, setToastVariant] = useState('success');
//   const [coolDown, setCoolDown] = useState(0);
//   const dispatch = useDispatch();
//   const { status, error } = useSelector((state) => state.auth);

//   const calculateCoolDown = () => {
//     const lastRequestTime = localStorage.getItem('customerForgotPasswordLastRequestTime');
//     if (lastRequestTime) {
//       const elapsed = (Date.now() - lastRequestTime) / 1000;
//       const remaining = Math.max(60 - elapsed, 0);
//       setCoolDown(remaining);
//     }
//   };

//   const handleSubmit = async (values, { setSubmitting, resetForm }) => {
//     try {
//       const result = await dispatch(userForgotPassword(values)).unwrap();
//       if (result && result.success) {
//         setToastMessage(result.message || 'Sent link to mail successfully!');
//         setToastVariant('success');
//         resetForm();
//         const now = Date.now();
//         localStorage.setItem('customerForgotPasswordLastRequestTime', now);
//         calculateCoolDown();
//       } else {
//         setToastMessage(result.message || 'Failed to send link to mail!');
//         setToastVariant('danger');
//         resetForm();
//       }
//     } catch (err) {
//       const errorMessage = err?.message || 'Failed to send link to mail!';
//       setToastMessage(errorMessage);
//       setToastVariant('danger');
//       resetForm();
//     } finally {
//       setShowToast(true);
//       setSubmitting(false);
//     }
//   };

//   useEffect(() => {
//     calculateCoolDown();
//   }, []);

//   useEffect(() => {
//     if (error) {
//       setToastMessage(error);
//       setToastVariant('danger');
//       setShowToast(true);
//     }

//     if (status === 'loading') {
//       setToastMessage('Sending link to mail...');
//       setToastVariant('warning');
//       setShowToast(true);
//     }
//   }, [status, error]);

//   useEffect(() => {
//     if (coolDown > 0) {
//       const timer = setTimeout(() => setCoolDown(coolDown - 1), 1000);
//       return () => clearTimeout(timer);
//     }
//   }, [coolDown]);

//   return (
//     <Row className="align-items-center justify-content-center g-0 min-vh-100">
//       <Col xxl={4} lg={6} md={8} xs={12} className="py-8 py-xl-0">
//         <ToastContainer position="top-end" className="p-3" style={{ zIndex: 1 }}>
//             <Toast
//                 onClose={() => setShowToast(false)}
//                 show={showToast}
//                 delay={5000}
//                 autohide
//                 bg={toastVariant}
//             >
//                 <Toast.Body style={{ color: "white", display: 'flex', alignItems: 'center' }}>
//                     <CheckCircle size={20} color="white" style={{ marginRight: 8 }} />
//                     {toastMessage}
//                 </Toast.Body>
//             </Toast>
//         </ToastContainer>
//         <Card className="smooth-shadow-md">
//           <Card.Body className="p-6">
//             <div className="mb-4">
//               <Link to="/"><img src="/images/brand/logo/logo-primary.svg" className="mb-2" alt="" /></Link>
//               <p className="d-flex mb-6 ml-2">Please enter your email</p>
//             </div>
//             <Formik
//               initialValues={{ email: '' }}
//               validationSchema={ForgotPasswordSchema}
//               onSubmit={handleSubmit}
//             >
//               {({ errors, touched, isSubmitting, handleChange, handleBlur, handleSubmit }) => (
//                 <form onSubmit={handleSubmit}>
//                   <div className="mb-3">
//                     <label htmlFor="email" className="d-flex form-label">Email</label>
//                     <Field
//                       id="email"
//                       name="email"
//                       type="email"
//                       placeholder="Enter your email here"
//                       className={`form-control ${touched.email && errors.email ? 'is-invalid' : ''}`}
//                       onChange={handleChange}
//                       onBlur={handleBlur}
//                     />
//                     {touched.email && errors.email ? (
//                       <div className="invalid-feedback">{errors.email}</div>
//                     ) : null}
//                   </div>

//                   <div className="d-grid w-28 d-flex justify-content-center mt-5">
//                     <Button variant="primary" type="submit" disabled={isSubmitting || coolDown > 0}>
//                         {isSubmitting ? (
//                             <>
//                             <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
//                             <span className="visually-hidden">Loading...</span>
//                             </>
//                         ) : (
//                             coolDown > 0 ? `Wait ${Math.ceil(coolDown)}s For Next Request` : 'Forgot Password'
//                         )}
//                     </Button>
//                   </div>
//                   <div className="d-grid w-28 d-flex justify-content-center mt-3">
//                     <Link to="/" className='text-decoration-underline'>Sign In</Link>
//                   </div>
//                 </form>
//               )}
//             </Formik>
//           </Card.Body>
//         </Card>
//       </Col>
//     </Row>
//   );
// }

// export default ForgotPassword;

export default function ForgotPassword() {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastVariant, setToastVariant] = useState("toast-success");
  const [coolDown, setCoolDown] = useState(0);
  const dispatch = useDispatch();
  const { status, error } = useSelector((state) => state.auth);

  const calculateCoolDown = () => {
    const lastRequestTime = localStorage.getItem(
      "customerForgotPasswordLastRequestTime"
    );
    if (lastRequestTime) {
      const elapsed = (Date.now() - lastRequestTime) / 1000;
      const remaining = Math.max(60 - elapsed, 0);
      setCoolDown(remaining);
    }
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const result = await dispatch(userForgotPassword(values)).unwrap();
      if (result && result.success) {
        setToastMessage(result.message || "Sent link to mail successfully!");
        setToastVariant("toast-success");
        resetForm();
        const now = Date.now();
        localStorage.setItem("customerForgotPasswordLastRequestTime", now);
        calculateCoolDown();
      } else {
        setToastMessage(result.message || "Failed to send link to mail!");
        setToastVariant("toast-danger");
        resetForm();
      }
    } catch (err) {
      const errorMessage = err?.message || "Failed to send link to mail!";
      setToastMessage(errorMessage);
      setToastVariant("toast-danger");
      resetForm();
    } finally {
      setShowToast(true);
      setSubmitting(false);
    }
  };

  useEffect(() => {
    calculateCoolDown();
  }, []);

  useEffect(() => {
    if (error) {
      setToastMessage(error);
      setToastVariant("danger");
      setShowToast(true);
    }

    if (status === "loading") {
      setToastMessage("Sending link to mail...");
      setToastVariant("warning");
      setShowToast(true);
    }
  }, [status, error]);

  useEffect(() => {
    if (coolDown > 0) {
      const timer = setTimeout(() => setCoolDown(coolDown - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [coolDown]);
  return (
    <>
      <ToastContainer position="top-end" className="p-3">
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={5000}
          autohide
          bg={toastVariant}
          className="position-fixed top-3 end-0 me-3 shadow-none"
        >
          <Toast.Body className="d-flex align-items-center gap-1 lh-sm">
            <CheckmarkBadge04Icon
              width={18}
              height={18}
              color="#28c76f"
              strokeWidth="2"
            />
            <span className="text-capitalize">{toastMessage}</span>
          </Toast.Body>
        </Toast>
      </ToastContainer>
      <Container fluid="xxl" className="mx-auto px-4">
        <div className="d-flex align-items-center justify-content-center py-4  forgot-password-content">
          <div className="position-relative w-100 py-4 forgot-password-sub-conetnt">
            <div className="bg-white rounded-2 p-5 common-shadow forgot-password-card">
              <h1 className="fs-5 d-flex align-content-center justify-content-center mb-4">
                <Link
                  to="https://invitewala.in/"
                  className=" text-decoration-none  d-inline-block"
                >
                  <Image
                    src="/assets/images/logo/logo-black.svg"
                    alt="Site-Logo"
                  />
                </Link>
              </h1>
              <div className="mb-4 welcome-content">
                <h4 className="mb-1 fw-medium">Forgot Password? 🔒</h4>
                <p>
                  Enter your email and we'll send you instructions to reset your
                  password
                </p>
              </div>
              <Formik
                initialValues={{ email: "" }}
                validationSchema={ForgotPasswordSchema}
                onSubmit={handleSubmit}
              >
                {({
                  errors,
                  touched,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="mb-4">
                      <CommonFormLabel title="Email" />
                      <Input
                        id="email"
                        name="email"
                        type="email"
                        placeholder="Enter your email"
                        onChange={handleChange}
                        className={`shadow-none  ${touched.email && errors.email ? "error" : "correct"
                          }`}
                      />
                      {touched.email && errors.email ? (
                        <ValidationFeedback title={errors.email} />
                      ) : null}
                    </div>

                    {
                      isSubmitting ? <ButtonLoader
                        icon={
                          <Spinner
                            animation="border" className="text-white br-2_5 btn-loader-wh-18" />
                        }
                        title="Loading..."
                        className="w-100 mb-4 btn-primary primary-shadow "
                      /> : <CommomButton
                        title={coolDown > 0 ? `Wait ${Math.ceil(coolDown)}s For Next Request` : 'Send Reset Link'}
                        type="submit"
                        disabled={isSubmitting || coolDown > 0}
                        className="w-100 mb-4 btn-primary primary-shadow "
                      />
                    }

                    <div className="d-flex align-items-center justify-content-center backtologin-link">
                      <Link
                        to="/"
                        className="text-decoration-none  d-flex align-items-center"
                      >
                        <span className="me-1">
                          <ArrowLeftIcon
                            width={20}
                            height={20}
                            color="#7367F0"
                            strokeWidth="1.5"
                          />
                        </span>
                        Back to login
                      </Link>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
}
