import React, { useState, useRef, useEffect } from "react";
import EmojiPicker from "emoji-picker-react";
import { Smile } from "react-feather";

const EmojiPickerWrapper = ({ onEmojiClick }) => {
    const [isEmojiPickerVisible, setEmojiPickerVisibility] = useState(false);
    const emojiPickerRef = useRef();

    const handleEmojiClick = (event, emojiObject) => {
        onEmojiClick(event, emojiObject);
        setEmojiPickerVisibility(false);
    };

    const handleOutsideClick = (event) => {
        if (
            emojiPickerRef.current &&
            !emojiPickerRef.current.contains(event.target)
        ) {
            setEmojiPickerVisibility(false);
        }
    };

    const handleTogglePicker = () => {
        setEmojiPickerVisibility(!isEmojiPickerVisible);
    };

    // Attach or detach the outside click listener when the visibility changes
    useEffect(() => {
        if (isEmojiPickerVisible) {
            document.addEventListener("click", handleOutsideClick);
        } else {
            document.removeEventListener("click", handleOutsideClick);
        }

        return () => {
            document.removeEventListener("click", handleOutsideClick);
        };
    }, [isEmojiPickerVisible]);

    return (
        <div ref={emojiPickerRef}>
            {isEmojiPickerVisible && (
                <EmojiPicker
                    onEmojiClick={handleEmojiClick}
                    suggestedEmojisMode="recent"
                    height={350}
                    lazyLoadEmojis={true}
                    skinTonesDisabled={true}
                    previewConfig={{
                        showPreview: false,
                    }}
                    autoFocusSearch={true}
                />
            )}
            <Smile
                className="cursor-pointer m-25"
                size={14}
                onClick={handleTogglePicker}
                style={{ width: "20px", height: "20px" }}
            />
        </div>
    );
};

export default EmojiPickerWrapper;
