import {
  DashboardCircleIcon,
  HourglassIcon,
  // TaskEdit02Icon,
  // TimeScheduleIcon,
  // Share02Icon,
  // CheckmarkCircle04Icon,
  // InformationCircleIcon,
} from "../../../../icons/icons";

export const CampaignsDataTabs = [
  {
    id: "guests",
    icon: (
      <DashboardCircleIcon
        width={16}
        height={16}
        color="#444050"
        strokeWidth="2"
      />
    ),
    title: "All Recipient",
  },
  {
    id: "test",
    icon: (
      <HourglassIcon width={16} height={16} color="#444050" strokeWidth="2" />
    ),
    title: "Test message",
  },
];
