import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosapi from "../utilities/axiosApi";

export const userLogin = createAsyncThunk(
    'user/login',
    async (loginDetails, { rejectWithValue }) => {
      try {
        const response = await axiosapi.post('/api/customer/login', loginDetails);
        return response.data; 
      } catch (err) {
        return rejectWithValue(err.response.data);
      }
    }
  );

export const userDetails = createAsyncThunk(
  'user/details',
  async () => {
      const response = await axiosapi.get('/api/customer/me');
      return response;
  }
)

export const userLogout = createAsyncThunk(
  'user/logout',
  async () => {
      // console.log('userLogout');
      const response = await axiosapi.get('/api/customer/logout');
      return response.data;
  }
)

export const userUpdatePassword = createAsyncThunk(
  'user/update-password',
  async (formData, { rejectWithValue }) => {
    try{
      const response = await axiosapi.post('/api/customer/change-password', formData);
      return response;
  }
  catch (err) {
    return rejectWithValue(err.response.data);
  }
}
)

export const userForgotPassword = createAsyncThunk(
  'user/forgot-password',
  async (forgotPasswordDetails, { rejectWithValue }) => {
    try {
      const response = await axiosapi.post('/api/customer/forgot-password', forgotPasswordDetails);
      return response.data; 
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const userResetPassword = createAsyncThunk(
  'user/reset-password',
  async (resetPasswordDetails, { rejectWithValue }) => {
    try {
      const response = await axiosapi.post('/api/customer/reset-password', resetPasswordDetails);
      return response.data; 
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    isAuthenticated: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(userLogin.fulfilled, (state, { payload }) => {
      if (payload.data.success === true) {
        state.isAuthenticated = true;
      }
    });
    builder.addCase(userDetails.fulfilled, (state, { payload }) => {
      if (payload.data.success === true) {
        state.isAuthenticated = true;
        state.user = payload.data.data;
      }
    });
    builder.addCase(userDetails.rejected, (state, { payload }) => {
      if (payload === undefined) {
        state.isAuthenticated = false;
        state.user = null;
      }
    });
    builder.addCase(userLogout.fulfilled, (state, { payload }) => {
      // console.log('LogOut Slice : ', payload);
      if (payload.success === true) {
        // console.log("State : ", state.user);
        state.isAuthenticated = false;
        state.user = null;
      }
    });
  },
});

export default authSlice.reducer;
