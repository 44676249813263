import React from "react";
import { Form } from "react-bootstrap";

export default function Input({
  type,
  name,
  placeholder,
  value,
  id,
  size,
  onChange,
  isValid,
  className,
  accept,
  as,
  ref,
  max,
  min,
  step,
  ariaLabel,
  ariaDescribedby,
  maxLength,
  autoComplete,
  style,
  minLength,
  onBlur,
  onSelect,
}) {
  return (
    <Form.Control
      as={as}
      type={type}
      name={name}
      placeholder={placeholder}
      value={value}
      id={id}
      size={size}
      onChange={onChange}
      isValid={isValid}
      accept={accept}
      ref={ref}
      max={max}
      min={min}
      step={step}
      aria-label={ariaLabel}
      aria-describedby={ariaDescribedby}
      minLength={minLength}
      maxLength={maxLength}
      autoComplete={autoComplete}
      style={style}
      onBlur={onBlur}
      onSelect={onSelect}
      className={`common-input ${className}`}
    />
  );
}
