import React from 'react'
import { Form } from 'react-bootstrap';

export default function Switch({ type, id, label, as, name, className,onChange, checked }) {
    return (
        <Form.Check
            type={type}
            id={id}
            label={label}
            name={name}
            as={as}
            className={`custom-switch ${className}`}
            onChange={onChange}
            checked={checked}
        />
    )
}
