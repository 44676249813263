import React, { useEffect, useState } from "react";
import {
  Container,
  Col,
  Row,
  Button,
  ToastContainer,
  Toast,
  InputGroup,
} from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
// import './profilemodule.css';
import "./profileModule.css";
import CommonLayer from "../../layouts/master/index";
import { useDispatch, useSelector } from "react-redux";
import { userDetails, userUpdatePassword } from "../../../store/authSlice";
// import { CheckCircle } from "react-feather";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { passwordSchema } from "../../../schema/validationSchemas";
import { fetchCountry } from "../../../store/customerSlice";
import { useLoader } from "../../../context/LoaderContext";
import { CheckmarkBadge04Icon } from "../../../icons/icons";

export default function Detail() {
  // const [value, setValue] = useState("");

  const { setLoading } = useLoader();

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("toast-success");

  //   const [name, setName] = useState("");
  //   const [email, setEmail] = useState("");
  //   const [mobileNumber, setMobileNumber] = useState("");
  const user = useSelector((state) => state.auth.user);
  // const countries = useSelector((state) => state.events.countries);

  //   console.log('User : ', user);

  const initialPasswordUpdate = {
    current_password: "",
    new_password: "",
    new_password_confirmation: "",
  };

  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    dispatch(userDetails()).then((action) => {
      if (action.payload && action.payload.data.success) {
        // console.log("Action : ", action.payload);
        // setName(action.payload.data.data.name);
        // setEmail(action.payload.data.data.email);
        // setMobileNumber(action.payload.data.data.mobile_number);
        // setCountry(action.payload.data.data.country_id);
      }
    });
    dispatch(fetchCountry())
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch, setLoading]);

  // const getCountryName = (countryId) => {
  //   const country = countries.find((c) => c.id === countryId);
  //   return country ? country.name : "Not Found";
  // };

  const handlePasswordChange = (
    values,
    { setSubmitting, setErrors, resetForm }
  ) => {
    dispatch(userUpdatePassword(values))
      .then((action) => {
        if (action.payload && action.payload.data.success) {
          setSnackbarMessage(action.payload.data.message);
          setSnackbarVariant("toast-success");
          setShowSnackbar(true);
          resetForm();
        } else {
          setErrors(action.payload.data);
          setSnackbarMessage(
            action.payload.data.message || "Failed to update password"
          );
          setSnackbarVariant("toast-danger");
          setShowSnackbar(true);
        }
      })
      .catch((error) => {
        setSnackbarMessage(
          "Update failed: Network or server error" + error.message
        );
        setSnackbarVariant("toast-danger");
        setShowSnackbar(true);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const titles = [
    { name: 'Profile', path: '/customers' },
  ];

  return (
    <CommonLayer titles={titles}>
      <ToastContainer position="top-end" className="p-3">
        <Toast
          onClose={() => setShowSnackbar(false)}
          show={showSnackbar}
          delay={3000}
          autohide
          bg={snackbarVariant}
          className="position-fixed top-3 end-0 me-3 shadow-none"
        >
          <Toast.Body className="d-flex align-items-center gap-1 lh-sm">
            <CheckmarkBadge04Icon
              width={18}
              height={18}
              color="#28c76f"
              strokeWidth="2"
            />
            <span className="text-capitalize">{snackbarMessage}</span>
          </Toast.Body>
        </Toast>
      </ToastContainer>

      <Container fluid className="px-6">
        <Row>
          <Col lg={12} md={12} xs={12}>
            {/* Page header */}
            <div>
              <div className="d-flex justify-content-between align-items-center top_title">
                <div className="mb-2 mb-lg-0">
                  <h3 className="mb-0 subhead_title">Profile</h3>
                </div>
              </div>
            </div>
          </Col>
          <Col xl={12} lg={12} md={12} xs={12} className="mt-6">
            <div className="position-relative overflow-hidden border-radius-8 tablecontainer">
              <Tabs
                defaultActiveKey="profilelist"
                className="mb-5 border-0 tablist_data"
                id="uncontrolled-tab-example"
              >
                <Tab
                  className="row customer_data"
                  eventKey="profilelist"
                  title="Profile"
                >
                  <div className="input_head position-relative d-flex align-items-center justify-content-between">
                    <h5 className="float-start m-0 fw-500">Profile Details</h5>
                  </div>
                  <div className="tablecontent_details col-8 position-relative border-radius-8">
                    <h6 className="fw-500">Personal Details</h6>
                    <div className="row justify-content-cener align-item-center pt-4 pb-5">
                      <div class="position-relative col-6 floating-label-content">
                        <label class="floating-label w-100 text-start p-0">
                          Name
                        </label>
                        <input
                          class="w-100 bg-white fs-16 floating-input"
                          type="text"
                          placeholder=""
                          disabled
                          value={user.name}
                        />
                      </div>
                      <div class="position-relative col-6 floating-label-content">
                        <label class="w-100 text-start floating-label p-0">
                          Phone No.
                        </label>
                        <input
                          class="w-100 bg-white fs-16 floating-input"
                          type="number"
                          placeholder=" "
                          disabled
                          value={user.mobile_number}
                        />
                      </div>
                      {/* <div class="position-relative col-6 floating-label-content">
                      <label class="w-100 text-start floating-label p-0">
                          Country
                        </label>
                        <input
                          class="w-100 bg-white fs-16 floating-input"
                          type="text"
                          placeholder=" "
                          disabled
                          value={getCountryName(user.country_id)}
                        />
                        
                      </div> */}
                      <div class="position-relative col-6 floating-label-content">
                        <label class="floating-label w-100 text-start p-0">
                          Email
                        </label>
                        <input
                          class="w-100 bg-white fs-16 floating-input"
                          type="email"
                          placeholder=""
                          //   autoComplete="off"
                          disabled
                          value={user.email}
                        />
                      </div>
                      <div class="position-relative col-6 floating-label-content">
                        <label class="floating-label w-100 text-start p-0">
                          Organization Name
                        </label>
                        <input
                          class="w-100 bg-white fs-16 floating-input"
                          type="organization_name"
                          placeholder=""
                          //   autoComplete="off"
                          disabled
                          value={user?.organization_name}
                        />
                      </div>
                      <div class="position-relative col-6 floating-label-content">
                        <label class="floating-label w-100 text-start p-0">
                          Organization Type
                        </label>
                        <input
                          class="w-100 bg-white fs-16 floating-input"
                          type="organization_type"
                          placeholder=""
                          //   autoComplete="off"
                          disabled
                          value={user?.organization_type?.name}
                        />
                      </div>
                      <div class="position-relative col-6 floating-label-content">
                        <label class="floating-label w-100 text-start p-0">
                          State
                        </label>
                        <input
                          class="w-100 bg-white fs-16 floating-input"
                          type="state"
                          placeholder=""
                          //   autoComplete="off"
                          disabled
                          value={user?.state?.name}
                        />
                      </div>
                      <div class="position-relative col-6 floating-label-content">
                        <label class="floating-label w-100 text-start p-0">
                          City
                        </label>
                        <input
                          class="w-100 bg-white fs-16 floating-input"
                          type="city"
                          placeholder=""
                          //   autoComplete="off"
                          disabled
                          value={user?.city?.name}
                        />
                      </div>
                    </div>
                    {/* <div class="">
                      <Button type="button" class="">
                        Add Reseller
                      </Button>
                    </div> */}
                  </div>
                </Tab>
                <Tab
                  className="row customer_data"
                  eventKey="settingdetals"
                  title="Settings"
                >
                  <Formik
                    initialValues={initialPasswordUpdate}
                    validationSchema={passwordSchema}
                    onSubmit={handlePasswordChange}
                  >
                    {({ isSubmitting }) => (
                      <Form>
                        <div className="input_head position-relative d-flex align-items-center justify-content-between">
                          <h5 className="float-start m-0 fw-500">Setting</h5>
                          {showSnackbar}
                        </div>
                        <div className="tablecontent_details col-4 position-relative border-radius-8">
                          <h6 className="fw-500">Reset Password</h6>
                          <div className="row justify-content-cener align-item-center pt-4 pb-5 flex-column">
                            <div class="position-relative col-10 floating-label-content">
                              <InputGroup>
                                <label class="w-100 text-start p-0 floating-label">
                                  Current Password
                                </label>
                                <Field
                                  class="w-100 bg-white fs-16 floating-input"
                                  type="password"
                                  placeholder=""
                                  name="current_password"
                                  //   autoComplete="off"
                                />

                                <div className="required_alert text-start">
                                  <ErrorMessage
                                    name="current_password"
                                    component="p"
                                    className="text-danger"
                                  />
                                </div>
                              </InputGroup>
                            </div>
                            <div class="position-relative col-10 floating-label-content">
                              <InputGroup>
                                <label class="w-100 text-start p-0 floating-label">
                                  New Password
                                </label>
                                <Field
                                  class="w-100 bg-white fs-16 floating-input"
                                  type="password"
                                  placeholder=""
                                  name="new_password"
                                />

                                <div className="required_alert text-start">
                                  <ErrorMessage
                                    name="new_password"
                                    component="p"
                                    className="text-danger"
                                  />
                                </div>
                              </InputGroup>
                            </div>
                            <div class="position-relative col-10 floating-label-content">
                              <InputGroup>
                                <label class="w-100 text-start p-0 floating-label">
                                  Confirm Password
                                </label>
                                <Field
                                  class="w-100 bg-white fs-16 floating-input"
                                  type="password"
                                  placeholder=" "
                                  name="new_password_confirmation"
                                />

                                <div className="required_alert text-start">
                                  <ErrorMessage
                                    name="new_password_confirmation"
                                    component="p"
                                    className="text-danger"
                                  />
                                </div>
                              </InputGroup>
                            </div>
                          </div>
                          <div class="">
                            <Button type="submit" class="btn btn-primary">
                              Reset
                            </Button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </Tab>
              </Tabs>
            </div>
          </Col>
        </Row>
      </Container>
    </CommonLayer>
  );
}
