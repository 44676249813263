import * as Yup from 'yup';

export const loginSchema = Yup.object().shape({
    email: Yup.string().min(1, 'Minimum email length is 1').max(190, 'Maximum email length is 190').required('Email is required'),
    password: Yup.string().min(8, 'Minimum password length is 8').max(30, 'Maximum password length is 30').required('Password is required'),
});

export const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string().min(1, 'Minimum email length is 1').max(190, 'Maximum email length is 190').required('Email is required'),
});

export const ResetPasswordSchema = Yup.object().shape({
  new_password: Yup.string()
    .min(8, 'Minimum password length is 8')
    .max(30, 'Maximum password length is 30')
    .required('New password is required'),
  new_password_confirmation: Yup.string()
    .oneOf([Yup.ref('new_password'), null], 'Passwords must match')
    .required('Confirm password is required'),
});

export const resellerSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  country_code: Yup.string().required('Country code is required'),
  mobile_number: Yup.string().required('Mobile number is required'),
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
  password: Yup.string()
    .min(8, 'Minimum password length is 8')
    .max(30, 'Maximum password length is 30')
    .required('Password is required'),
  referral_code: Yup.string().required('Referral code is required'),
  credits: Yup.number()
    .typeError('Credits must be a number')
    .required('Credits are required'),
  country_id: Yup.number()
    .typeError('Country ID must be a number')
    .required('Country ID is required'),
  is_active: Yup.boolean().required('Status is required'),
  company_name: Yup.string().required('Company name is required'),
  company_address: Yup.string().required('Company address is required'),
  company_website: Yup.string().required('Company website is required'),
  company_logo: Yup.string().required('Company logo is required'),
});

export const editResellerSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  country_code: Yup.string().required('Country code is required'),
  mobile_number: Yup.string().required('Mobile number is required'),
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
  referral_code: Yup.string().required('Referral code is required'),
  credits: Yup.number()
    .typeError('Credits must be a number')
    .required('Credits are required'),
  country_id: Yup.number()
    .typeError('Country ID must be a number')
    .required('Country ID is required'),
  is_active: Yup.boolean().required('Status is required'),
  company_name: Yup.string().required('Company name is required'),
  company_address: Yup.string().required('Company address is required'),
  company_website: Yup.string().required('Company website is required'),
  company_logo: Yup.string().required('Company logo is required'),
});

export const passwordSchema = Yup.object().shape({
  current_password: Yup.string().required('Current password is required'),
  new_password: Yup.string()
    .min(8, 'Minimum password length is 8')
    .max(30, 'Maximum password length is 30')
    .required('New password is required'),
  new_password_confirmation: Yup.string()
    .oneOf([Yup.ref('new_password'), null], 'Passwords must match')
    .required('Confirm password is required'),
});

