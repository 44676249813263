import React from "react";
import CommonLayout from "../../layouts/master/index";
import List from "./List";


export default function CreditsLog() {

  const titles = [
    { name: 'Credits History', path: '/customers' },
  ];

  return (
    <>
      <CommonLayout titles={titles}>    
        <div>
          <List
          />
        </div>
      </CommonLayout>
    </>
  );
}
