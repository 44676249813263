import { DashboardCircleIcon, MoneySend02Icon, CheckmarkCircle04Icon, CancelCircleHalfDotIcon } from "../../../icons/icons";

export const RechargeHistoryTabs = [
    {
        id: "",
        icon: <DashboardCircleIcon width={16} height={16} color="#444050" strokeWidth="2" />,
        title: "All",
    },
    {
        id: "placed",
        icon: <MoneySend02Icon width={16} height={16} color="#444050" strokeWidth="2" />,
        title: "Requested",
    },
    {
        id: "completed",
        icon: <CheckmarkCircle04Icon width={16} height={16} color="#444050" strokeWidth="2" />,
        title: "Completed",
    },
    {
        id: "rejected",
        icon: <CancelCircleHalfDotIcon width={16} height={16} color="#444050" strokeWidth="2" />,
        title: "Rejected",
    }
];