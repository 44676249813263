import React from "react";
import { Form } from "react-bootstrap";

export default function CommonFormLabel({ htmlFor, title }) {
  return (
    <Form.Label htmlFor={htmlFor} className="mb-1 common-form-label">
      {title}
    </Form.Label>
  );
}
