import React from 'react'
import { Button } from "react-bootstrap";

export default function ButtonLoader({ icon, title, className, }) {
    return (
        <Button variant="link" className={`border-none rounded-2 text-decoration-none common-btn ${className}`}>

            <div className="d-flex align-items-center justify-content-center gap-2">
                {icon && <span>{icon}</span>}
                {title && <div className="fw-medium text-white text-capitalize">{title}</div>}
            </div>
        </Button>
    )
}
